import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import './ProjectTeaser.scss';

interface Props {
  data: any;
}

const ProjectTeaser: FC<Props> = ({ data }: Props) => {
  return (
    <div className="project-teaser">
      <Link to={`/projects/${data.slug}`}>
        <GatsbyImage
          loading="lazy"
          image={data.thumbnailImage.gatsbyImageData}
          alt={data.thumbnailImage.title}
          style={{
            aspectRatio: '1 / 1.025',
          }}
          imgStyle={{
            objectFit: 'cover',
          }}
        />
        <h2>{data.projectTitle}</h2>
        <p>{data.architectName}</p>
      </Link>
    </div>
  );
};

export default ProjectTeaser;
