import Layout from '../components/Layout';
import ProjectTeaser from '../components/ProjectTeaser';
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import './projects.scss';

interface Props {
  data: any;
}

const Projects: FC<Props> = ({ data }: Props) => {
  return (
    <Layout>
      <div className="projects">
        {data.allContentfulProject.nodes.map((project: any) => (
          <ProjectTeaser key={project.slug} data={project} />
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PortfolioPage {
    allContentfulProject(sort: { fields: order }) {
      nodes {
        architectName
        projectTitle
        slug
        thumbnailImage {
          gatsbyImageData(
            height: 1334
            width: 2000
            placeholder: BLURRED
            outputPixelDensities: [0.25, 0.5, 1]
          )
          title
        }
      }
    }
  }
`;

export default Projects;
